import SuccessLottie from 'common/assets/lottie/success-lottie.json';
import dynamic from 'next/dynamic';

export enum ActionStatusLottieType {
  Success = 'success',
}

interface Props {
  type: ActionStatusLottieType;
  size?: number | string;
}

export default function ActionStatusLottie(props: Props) {
  const { type, size = 233 } = props;
  const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

  let animationData: any = undefined;
  switch (type) {
    case ActionStatusLottieType.Success:
      animationData = SuccessLottie;
  }
  if (!animationData) {
    return (
      <div
        style={{
          width: size,
          height: size,
        }}
        role="presentation"
      />
    );
  }
  return (
    <Lottie
      animationData={SuccessLottie}
      autoplay
      style={{
        width: size,
        height: size,
      }}
      loop={0}
      role="presentation"
    />
  );
}
